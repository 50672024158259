<script>
import { authMethods } from '@state/helpers'
import AuthPageCard from "@components/auth/AuthPageCard";
export default {
  components: {AuthPageCard},
  page: {
    title: 'Εγγραφή',
    meta: [{ name: 'description', content: `Εγγραφείτε στο athlisis` }],
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
    }
  },
  computed: {
  },
  methods: {
    ...authMethods,
    // Try to register the user in with the email, firstName, lastName
    // and password they provided.
    tryToRegisterIn() {
      this.tryingToRegister = true;
      // Reset the regError if it existed.
      this.regError = null;
      return this.register({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: this.password,
      }).then((token) => {

          this.tryingToRegister = false;
          this.isRegisterError = false;
          // Redirect to the originally requested page, or to the confirm-account page
          this.$router.push(this.$route.query.redirectFrom || { name: 'confirm-account' });

        }).catch((error) => {
          this.tryingToRegister = false;
          this.regError = error.response? error.response.data.message: "";
          this.isRegisterError = true
        })
    },
  },
}
</script>

<template>

  <div id="register">

    <auth-page-card subtitle="Δεν έχετε λογαριασμό;<br> Δημιουργήστε έναν άμεσα και γίνετε μέλος του <strong>athlisis.</strong>">
      <template v-slot:default>

        <div>
          <b-alert v-model="isRegisterError" variant="danger" dismissible>
            {{regError}}
          </b-alert>

          <b-form @submit.prevent="tryToRegisterIn">

            <b-form-group id="firstName-group" label="Όνομα" label-for="firstName">
              <b-form-input id="firstName" v-model="firstName" type="text" required placeholder="Εισάγετε το όνομά σας"></b-form-input>
            </b-form-group>

            <b-form-group id="lastName-group" label="Επώνυμο" label-for="lastName">
              <b-form-input id="lastName" v-model="lastName" type="text" required placeholder="Εισάγετε το επώνυμό σας"></b-form-input>
            </b-form-group>

            <b-form-group id="email-group" label="E-mail" label-for="email">
              <b-form-input id="email" v-model="email" type="email" required placeholder="Εισάγετε το e-mail σας"></b-form-input>
            </b-form-group>

            <b-form-group id="password-group" label="Κωδικός πρόσβασης" label-for="password">
              <b-form-input id="new-password" v-model="password" type="password" required placeholder="Εισάγετε έναν κωδικό πρόσβασης"></b-form-input>
            </b-form-group>

            <b-form-group id="button-group" class="mt-4">
              <b-button type="submit" variant="primary" class="btn-block"><i class="fe-user-plus"></i> Εγγραφή</b-button>
            </b-form-group>
          </b-form>
        </div>
      </template>

      <template v-slot:under-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-white-50">Έχετε ήδη λογαριασμό;
              <router-link tag="a" to="/login" class="text-white ml-1"><b>Συνδεθείτε</b></router-link>
            </p>
          </div>
        </div>
      </template>
    </auth-page-card>
  </div>
</template>

<style lang="scss" module></style>
