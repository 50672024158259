<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5" :class="containerClasses || null">
      <div class="card">
        <div class="card-body p-4">
          <div class="text-center w-75 m-auto">
            <router-link to="/">
              <span><img src="@assets/images/athlisis-logo.svg" class="mt-4 mt-md-0" alt="" height="40"/></span>
              <h5 v-if="isEducational" class="text-red">EDUCATIONAL</h5>
            </router-link>
            <p class="text-muted mb-4 mt-3" v-html="subtitle"></p>
          </div>
          <slot></slot>
        </div>
        <!-- end card-body -->
      </div>
      <slot name="under-card"></slot>
    </div>
  </div>

</template>

<script>

export default{

  props:{
    subtitle: String,
    containerClasses: String
  }
}
</script>